import { MetaSwiper } from "./meta-settings.js";
import { CountUp } from "./plugins/countUp.min.js";
//import { Swiper } from "./plugins/swiper-bundle.esm.browser.min.js";
import { Swiper } from "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.4/swiper-bundle.esm.browser.js";
// import MetaMap from "./plugins/metaMap.v2.js";
import {
  computePosition,
  shift,
  flip,
  autoUpdate,
  offset,
} from "https://cdn.jsdelivr.net/npm/@floating-ui/dom@1.4.3/+esm";

document.addEventListener("DOMContentLoaded", function () {
  const mainWarapper = document.querySelector('.main');

  if (mainWarapper) {
      const sections = mainWarapper.querySelectorAll('section');
      const footerWrapper = document.querySelector('.footer');

      sections.forEach((section, i) => {
        if (section.classList.contains('offices') || section.classList.contains('trending') && i === sections.length - 1) {
          footerWrapper.style.marginTop = '0px';
        }
      });
  }

  if (document.querySelector('.js-dropdown')) {
    const btn = document.querySelector('.js-dropdown');
    const btnChevron = btn.querySelector('.header__link--btn span');
    const btnTitle = btn.querySelector('.header__link--btn');
    const dropdown = document.querySelector('.header__dropdown');
    let timer;

    if (innerWidth > 1023) {
      btn.addEventListener('mouseenter', () => {
        btn.classList.add('open');
        dropdown.classList.add('open');
      });

      btn.addEventListener('mouseenter', () => {
        clearTimeout(timer);
      });

      btn.addEventListener('mouseleave', () => {
        timer = setTimeout(() => {
          btn.classList.remove('open');
          dropdown.classList.remove('open');
        }, 1000);
      });
    } else {
      btn.addEventListener('click', (e) => {
        if (e.target === btnChevron) {
          e.preventDefault();
          btn.classList.toggle('open');
          dropdown.classList.toggle('open');
        }
      });

      btnTitle.addEventListener('click', (e) => {
        if (!btnTitle.classList.contains('open')) {
          e.preventDefault();
          e.stopPropagation()
          btnTitle.classList.add('open');
          btn.classList.add('open');
          dropdown.classList.add('open');
        }
      });
    }

  }

  // choices
  const choicesArr = document.querySelectorAll(".js-choices");
  if (choicesArr) {
    choicesArr.forEach(select => {
      new Choices(select, {
        allowHTML: true,
        searchEnabled: false,
      });
    });
  }
  // end choices

  const verticalAccordion =
      document.getElementsByClassName("vertical-accordion")[0];
  if (verticalAccordion) {
    const verticalAccordionItemsArr = verticalAccordion.querySelectorAll(
        ".vertical-accordion__item"
    );

    verticalAccordionItemsArr.forEach((item, i) => {
      item.addEventListener("click", () => {
        document
            .querySelector(".vertical-accordion__item.active")
            .classList.remove("active");
        item.classList.add("active");
      });
    });
  }

  let safari12 = navigator.sayswho == "Safari 12";
  if (!safari12) {
    // plyr
    if (document.querySelector(".video-player")) {
      const videos = document.querySelectorAll(".video-player");

      videos.forEach(video => {
        const player = new Plyr(video, {
          controls: [
            "play-large",
            "play",
            "progress",
            "current-time",
            "duration",
            "mute",
            "volume",
            "captions",
            "fullscreen",
          ],
        });
      });
    }
    // end plyr
  }
  // care tabs
  function careContentToggler () {
    const btns = document.querySelectorAll(".care__btn");
    const content = document.querySelectorAll(".care__content");
    const bgs = document.querySelectorAll(".care__bg");

    btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        [bgs, btns, content].forEach(arr => {
          arr.forEach(item => {
            item.classList.remove("active");
          });
        });

        if (!i) {
          [bgs, btns, content].forEach(arr => {
            arr[1].classList.add("active");
          });
        }

        if (i) {
          [bgs, btns, content].forEach(arr => {
            arr[0].classList.add("active");
          });
        }
      });
    });
  }
  careContentToggler();
  // end care tabs

  // countUp
  const countsArr = [...document.getElementsByClassName(`counter__num`)];
  if (countsArr) {
    const defaultOptions = {
      separator: ",",
      enableScrollSpy: true,
      scrollSpyRunOnce: true,
    };

    countsArr.forEach(elem => {
      let html = elem.innerHTML;
      const number = html.match("\\d+")[0];
      let prefix = "";

      if (html.indexOf(number) > 0) {
        prefix = html.slice(0, html.indexOf(number));
        html = html.replace(prefix, "");
      }

      const suffix = html.replace(number, "");

      let optionsFromDataAttr = $(elem).data();

      for (const key in optionsFromDataAttr) {
        if (optionsFromDataAttr[key] === "") {
          optionsFromDataAttr[key] = true;
        }
      }

      optionsFromDataAttr.prefix = prefix;
      optionsFromDataAttr.suffix = suffix;

      new CountUp(
          elem.id,
          number,
          Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
      );
    });
  }
  // end countUp

  AOS.init({
    startEvent: "load",
    offset: 150,
    delay: 0,
    duration: 500,
    easing: "ease",
    once: true,
  });

  // fix ios height
  if (innerWidth < 1024) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
  // end fix ios height
  // burger-menu
  const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
        layoutElem = document.querySelector(".header__layout"),
        burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
      menuElem.classList.remove("active");
      scrollLock.enablePageScroll();
    };
    removeClass();

    menuElem.querySelectorAll("a").forEach(i => {
      i.addEventListener("click", e => {
        if (menuElem.classList.contains("active") && !i.classList.contains("open") && e.target != document.querySelector('.header__link--btn span')) {
          removeClass();
        }
      });
    });

    burgerElem.addEventListener("click", () => {
      if (menuElem.classList.contains("active")) {
        removeClass();
      } else {
        menuElem.classList.add("active");
        var method = 'margin'; //Available methods: none, padding, margin, width, max-width
        scrollLock.setFillGapMethod(method);
        scrollLock.disablePageScroll();

        scrollLock.addFillGapSelector('.header__navbar-box');
      }
    });

    window.addEventListener("resize", () => {
      if (innerWidth > 1023) {
        removeClass();
      }
    });

    layoutElem.addEventListener("click", () => {
      removeClass();
    });
  };

  if (document.querySelector(".header")) {
    burgerMenu(".header", ".header__burger");
  }
  // end burger-menu

  // sticky
  let scrollPoint;
  function stickyMenu() {
    const headerElem = document.querySelector(".header");

    if (window.scrollY >= 50) {
      headerElem.classList.add("sticky");
    } else {
      headerElem.classList.remove("sticky");
    }

    if (innerWidth > 1023) {
      if (window.scrollY > scrollPoint && window.scrollY >= 50) {
        headerElem.style.transform = "translateY(-100%)";
      } else {
        headerElem.style.transform = "unset";
      }

      scrollPoint = window.scrollY;
    }
  }

  window.addEventListener("scroll", stickyMenu);
  stickyMenu();
  // end sticky

  // simplebar
  if (document.querySelector(".header__simplebar") && innerWidth < 1024) {
    new SimpleBar(document.querySelector(".header__simplebar"));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper')
  }
  // end simplebar

  //  swiper

  if (document.querySelector(".prioritise")) {
    const sections = document.querySelectorAll(".prioritise");
    sections.forEach(section => {
      const slider = section.querySelector(".prioritise__slider");
      const slides = slider.querySelectorAll(".swiper-slide");
      const sliderWrapper = slider.querySelector(".swiper-wrapper");
      const sliderBtnPrev = section.querySelector(".prioritise__prev");
      const sliderBtnNext = section.querySelector(".prioritise__next");

      const swiper = new Swiper(slider, {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: sliderBtnNext,
          prevEl: sliderBtnPrev,
        },
        breakpoints: {
          414: {
            spaceBetween: 15,
            slidesPerView: 2,
          },
          568: {
            spaceBetween: 15,
            slidesPerView: 3,
          },
          768: {
            spaceBetween: 15,
            slidesPerView: 4,
          },
          1024: {
            spaceBetween: 15,
            slidesPerView: 6,
          },
        },
      });

      (slides.length < swiper.params.slidesPerView)
          ? sliderWrapper.classList.add('justify-center')
          : sliderWrapper.classList.remove('justify-center');
    })
  }

  if (document.querySelector(".follow-us")) {
    const sliders = document.querySelectorAll(".follow-us__slider");
    const container = document.querySelector(".follow-us");
    customTabs(
        container,
        ".follow-us__tab",
        ".follow-us__tab-content",
        sliders
    );

    new Swiper(sliders[0], {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        450: {
          slidesPerView: 2,
        },
        652: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
    });
  }

  if (document.querySelector(".meet-team-slider")) {
    MetaSwiper(`.meet-team-slider`, {
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".meet-team__prev",
        nextEl: ".meet-team__next",
      },
    });
  }

  if (document.querySelector(".gallery__slider")) {
    MetaSwiper(`.gallery__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        432: {
          slidesPerView: 2,
        },
        652: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: ".gallery__next",
        prevEl: ".gallery__prev",
      },
    });
  }

  if (document.querySelector(".positions__slider")) {
    const slidesArr = document.querySelectorAll('.positions__slider .swiper-slide');
    const sliderNav = document.querySelector('.positions__nav');

    if(slidesArr.length < 4) {
      sliderNav.style.display = 'none';
    }

    const swiper = new Swiper(`.positions__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".positions__next",
        prevEl: ".positions__prev",
      },
      breakpoints: {
        652: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });

    window.updatePositionSwiper = function () {
      swiper.update();
      swiper.slideTo(0);
    };
  }

  if (document.querySelector(".headlines")) {
    const sliderThumbs = new Swiper(".headlines__thumb", {
      watchSlidesProgress: true,
      freeMode: true,
      breakpoints: {
        0: {
          direction: "horizontal",
          slidesPerView: 2,
          spaceBetween: 20,
        },
        770: {
          direction: "horizontal",
          slidesPerView: 2,
          spaceBetween: 25,
        },
        1024: {
          direction: "vertical",
          slidesPerView: 3,
          spaceBetween: 25,
        },
      },
    });
    const sliderText = new Swiper(".headlines__video", {
      navigation: {
        nextEl: ".headlines__next",
        prevEl: ".headlines__prev",
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      grabCursor: true,
      thumbs: {
        swiper: sliderThumbs,
      },
      breakpoints: {
        0: {
          direction: "horizontal",
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1024: {
          direction: "vertical",
        },
      },
    });
  }

  if (document.querySelector(".counter__slider")) {
    const slides = document.querySelectorAll(".counter__slider .swiper-slide");
    const sliderWrapper = document.querySelector(".counter__slider .swiper-wrapper");
    const slider = MetaSwiper(`.counter__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".counter__next",
        prevEl: ".counter__prev",
      },
      breakpoints: {
        427: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        658: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        768: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 5,
        },
      },
    });

    (slides.length < slider.params.slidesPerView)
        ? sliderWrapper.classList.add('justify-center')
        : sliderWrapper.classList.remove('justify-center');
  }

  if (document.querySelector(".news__slider")) {
    MetaSwiper(`.news__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        652: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector(".reviews")) {
    const sliderThumbs = new Swiper(".reviews__thumb", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 40,
      watchSlidesProgress: true,
      navigation: {
        nextEl: ".reviews__next",
        prevEl: ".reviews__prev",
      },
      speed: 500,
      allowTouchMove: false,
      breakpoints: {
        770: {
          direction: "horizontal",
          slidesPerView: 3,
          spaceBetween: 25,
        },
        1024: {
          direction: "vertical",
          freeMode: true,
          slidesPerView: 3,
          spaceBetween: 25,
        },
      },
    });
    const sliderText = new Swiper(".reviews__text", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 48,
      watchSlidesProgress: true,
      navigation: {
        nextEl: ".reviews__next",
        prevEl: ".reviews__prev",
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      grabCursor: true,
      thumbs: {
        swiper: sliderThumbs,
      },
      breakpoints: {
        1024: {
          direction: "vertical",
        },
      },
    });
  }

  if (document.querySelector(".supply__slider") && innerWidth < 1024) {
    MetaSwiper(`.supply__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".supply__next",
        prevEl: ".supply__prev",
      },
      breakpoints: {
        652: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector(".details__slider")) {
    MetaSwiper(`.details__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        652: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector(".testimonials__slider")) {
    MetaSwiper(`.testimonials__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    });
  }
  if (document.querySelector(".meet-team__slider") && innerWidth < 1024) {
    MetaSwiper(`.meet-team__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".meet-team-slider__next",
        prevEl: ".meet-team-slider__prev",
      },
      breakpoints: {
        652: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector(".industries__slider") && innerWidth < 1024) {
    MetaSwiper(`.industries__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 20,
      grid: {
        rows: 2,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        569: {
          slidesPerView: 2,
          grid: {
            rows: 2,
          },
        },
        768: {
          slidesPerView: 3,
          grid: {
            rows: 2,
          },
        },
      },
    });
  }
  //  end swiper

  function customAccardion (triggers, openOne) {
    let triggersArr = document.querySelectorAll(triggers);

    if (openOne) {
      triggersArr.forEach(trigger => {
        trigger.addEventListener("click", () => {
          triggersArr.forEach(trigger => {
            trigger.classList.remove("active");
          });
          trigger.classList.add("active");
        });
      });
    } else {
      triggersArr.forEach(trigger => {
        trigger.addEventListener("click", () => {
          trigger.classList.toggle("active");
        });
      });
    }
  }

  if (document.querySelector(".mission")) {
    customAccardion(".mission__card", true);
  }

  // awards
  const awardsBoxes = document.querySelectorAll(".awards__wrapper");

  if (awardsBoxes) {
    const awardsSwiper = new Swiper(`.awards__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".awards__next",
        prevEl: ".awards__prev",
      },
      breakpoints: {
        374: {
          spaceBetween: 40,
          slidesPerView: 2,
        },
        658: {
          spaceBetween: 40,
          slidesPerView: 4,
        },
        768: {
          spaceBetween: 50,
          slidesPerView: 5,
        },
        1024: {
          spaceBetween: 73,
          slidesPerView: 7,
        },
      },
    });

    awardsBoxes.forEach(awardsBox => {
      const awards = awardsBox.querySelectorAll(".awards__logo");

      awards.forEach((item, index) => {
        const description = item.querySelector(".awards__logo-desc");

        function updatePosition () {
          computePosition(item, description, {
            placement: "top",
            middleware: [
              shift({
                padding: 25,
              }),
              flip(),
              offset({
                mainAxis: 20,
                crossAxis: 20,
              }),
            ],
            boundary: document.querySelector("#site"),
          }).then(({ x, y }) => {
            Object.assign(description.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });
        }

        const cleanup = autoUpdate(item, description, updatePosition);

        item.addEventListener("mouseenter", () => {
          item.parentElement.style.zIndex = 100;
        });

        item.addEventListener("mouseleave", () => {
          item.parentElement.style.zIndex = 1;
        });
      });
    });
  }
  // end awards

  // resources

  function customTabs (section, tabs, containers, sliders = false) {
    const tabTriggers = section.querySelectorAll(tabs);
    const tabContainers = section.querySelectorAll(containers);

    tabTriggers.forEach((tab, i) => {
      let newSlider;
      tab.addEventListener("click", () => {
        tabTriggers.forEach((tab, i) => {
          tab.classList.remove("active");
          tabContainers[i].classList.remove("active");
        });

        tab.classList.add("active");
        tabContainers[i].classList.add("active", "fade-in");

        if (sliders) {
          newSlider = new Swiper(sliders[i], {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            autoplay: {
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            },
            breakpoints: {
              450: {
                slidesPerView: 2,
              },
              652: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            },
          });
        }
      });
    });
  }

  function topicsGrid () {
    const triggers = document.querySelectorAll(".topics__head");

    triggers.forEach(trigger => {
      const container = trigger.nextElementSibling;

      customTabs(container, ".topics__tab", ".topics__tab-content");

      trigger.addEventListener("click", e => {
        if (trigger.classList.contains("open")) {
          trigger.classList.remove("open");
          trigger.nextElementSibling.classList.remove("open");
        } else {
          triggers.forEach(trigger => {
            trigger.classList.remove("open");
            trigger.nextElementSibling.classList.remove("open");
          });

          trigger.classList.add("open");
          trigger.nextElementSibling.classList.add("open");
        }
      });
    });
  }

  topicsGrid();

  function openCareerTopic () {
    const triggers = document.querySelectorAll(".topics__head");

    triggers.forEach((trigger, i) => {
      if (trigger.classList.contains("open")) {
        trigger.classList.remove("open");
        trigger.nextElementSibling.classList.remove("open");
      }

      if (i === 4) {
        trigger.classList.add("open");
        trigger.nextElementSibling.classList.add("open");
      }
    });
  }

  window.openCareerTopic = openCareerTopic;

  window.topicsGrid = topicsGrid;

  function firstGrid () {
    const triggers = document.querySelectorAll(".first__grid-head");

    triggers.forEach(trigger => {
      trigger.addEventListener("click", e => {
        if (trigger.classList.contains("open")) {
          trigger.classList.remove("open");
          trigger.nextElementSibling.classList.remove("open");
        } else {
          triggers.forEach(trigger => {
            trigger.classList.remove("open");
            trigger.nextElementSibling.classList.remove("open");
          });

          trigger.classList.add("open");
          trigger.nextElementSibling.classList.add("open");
        }
      });
    });
  }

  firstGrid();

  window.firstGrid = firstGrid;

  function resourcesAccardion (
      wrapperSelector,
      contentTrigger,
      contentSelector
  ) {
    const wrappers = document.querySelectorAll(wrapperSelector);

    wrappers.forEach(wrap => {
      const trigger = wrap.querySelector(contentTrigger);
      const content = wrap.querySelector(contentSelector);
      trigger.addEventListener("click", () => {
        let maxHeight = content.querySelector("div").clientHeight + "px";
        if (trigger.classList.contains("open")) {
          trigger.classList.remove("open");
          content.classList.remove("open");
          content.style.maxHeight = 0;
        } else {
          wrappers.forEach(wrap => {
            const trigger = wrap.querySelector(contentTrigger);
            const content = wrap.querySelector(contentSelector);

            trigger.classList.remove("open");
            content.classList.remove("open");
            content.style.maxHeight = 0;
          });
          trigger.classList.add("open");
          content.classList.add("open");
          content.style.maxHeight = maxHeight;
        }
      });
    });
  }

  function updResourcesAccardion() {
    resourcesAccardion(
        ".topics__accardion",
        ".topics__accardion-head",
        ".topics__accardion-content"
    );
  }
  updResourcesAccardion();

  resourcesAccardion(
      ".first__accardion",
      ".first__accardion-head",
      ".first__accardion-content"
  );

  window.resourcesAccardion = resourcesAccardion;
  window.updResourcesAccardion = updResourcesAccardion;

  // end resources

  if (document.querySelector("#regions")) {
    const select = new Choices("#regions", {
      allowHTML: true,
      shouldSort: false,
    });

    const input = document.querySelector("#regions")
    const hiddenInputWrap = document.querySelector(".contact-form__input-box--hidden")
    const hiddenInput = document.querySelector(".contact-form__input-box--hidden input")

    input.addEventListener('change', () => {
      if (input.value === 'other') {
        hiddenInputWrap.classList.add('active')
      } else {
        hiddenInputWrap.classList.remove('active')
        hiddenInput.value = ''
      }
    })
    window.selectArr.push(select);
  }

  let payRatesSwiperWr = document.querySelectorAll(".pay-rates-swiper-wr");
  payRatesSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector(".swiper");
      let nextEl = el.querySelector(".arrow-btn-next");
      let prevEl = el.querySelector(".arrow-btn-prev");
      let slidesCount = el.querySelectorAll(".swiper-slide").length;
      let metaSwiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        speed: 400,
        loop: false,
        threshold: 10,
        autoHeight: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
      });

      window.updatePayRatesSwiper = function () {
        metaSwiper.update();
        metaSwiper.slideTo(0);
      };
    }
  });
  const singleRangeSlider = () => {
    const singleRange = document.getElementsByClassName(
        "single-range-default"
    )[0];
    if (singleRange) {
      const slider = singleRange.querySelector("#single-range-default");
      const max = +slider.dataset.max;
      const min = +slider.dataset.min;
      const unit = slider.dataset?.unit || "£";
      const step = +slider.dataset.step;
      const inputsHidden = singleRange.querySelector(
          ".single-range-hidden-input"
      );
      const inputInfo = singleRange.querySelector(".single-range-info");
      const startValue = +inputsHidden.value;

      noUiSlider.create(slider, {
        start: [startValue],
        connect: "lower",
        step: step,
        range: {
          min: min,
          max: max,
        },
      });

      slider.noUiSlider.on("update", function (values, handle) {
        inputInfo.textContent = `${unit + Math.round(values[handle])} miles`;

        inputsHidden.value = Math.round(values[handle]);
      });
    }
  };
  singleRangeSlider();

  let filesInput = document.querySelectorAll('[type="file"]');
  if (filesInput) {
    filesInput.forEach(file => {
      file.addEventListener("change", function () {
        let uploadStatus =
            this.closest(".upload-btn").querySelector(".upload-status");
        uploadStatus.innerText = this.files[0].name;
      });
    });
  }


  /*map handler start*/
  let mapSwiperWr = document.querySelectorAll(".map-swiper-wr");
  mapSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector(".swiper");
      let nextEl = el.querySelector(".map-swiper__next");
      let prevEl = el.querySelector(".map-swiper__prev");
      let slidesCount = el.querySelectorAll(".swiper-slide").length;
      let swiper = new Swiper(swiperEl, {
        slidesPerView: "auto",
        spaceBetween: 15,
        speed: 500,
        threshold: 10,
        loop: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          200: {
            slidesPerView: "auto",
          },
          993: {
            slidesPerView: 2,
          },
        },
      });
      window.updateMapSwiper = function () {
        const mapSliderWrapper = document.querySelector(".map-swiper-wr");
        const map = document.getElementById("map");

        if (!mapSliderWrapper.classList.contains("active")) {
          mapSliderWrapper.classList.add("active");
        }

        map.classList.add("active");

        swiper.update();
        swiper.slideTo(0);
        swiper.autoplay.start();
      };
    }
  });

  // const branchMarkers = [{"title":"Newcastle Upon-Tyne","id":"pin-1","lat":"54.981956","long":"-1.600164"},{"title":"London","id":"pin-2","lat":"51.509865","long":"-0.118092"},{"title":"Leeds","id":"pin-3","lat":"53.79799","long":"-1.54947"},{"title":"Southampton","id":"pin-4","lat":"50.90773","long":"-1.38951"},{"title":"Bath","id":"pin-5","lat":"51.380001","long":"-2.360000"},{"title":"Bristol","id":"pin-6","lat":"51.271883","long":"-2.354794"},{"title":"Reading","id":"pin-7","lat":"51.454265","long":"-0.97813"},{"title":"Salisbuy","id":"pin-8","lat":"51.068787","long":"-1.794472"},{"title":"Southampton","id":"pin-9","lat":"50.909698","long":"-1.404351"},{"title":"Harrogate","id":"pin-11","lat":"53.990608","long":"-1.539110"},{"title":"Oxford","id":"pin-12","lat":"51.753738","long":"-1.263460"},{"title":"Dorset","id":"pin-13","lat":"50.721680","long":"-1.878530"},{"title":"Derby","id":"pin-14","lat":"52.921902","long":"-1.475640"},{"title":"Nottingham","id":"pin-15","lat":"52.950001","long":"-1.150000"},{"title":"Sheffield","id":"pin-16","lat":"53.383331","long":"-1.466667"},{"title":"Doncaster","id":"pin-17","lat":"53.522820","long":"-1.128462"},{"title":"Glasgow City","id":"pin-18","lat":"55.860916","long":"-4.251433"},{"title":"Manchester","id":"pin-21","lat":"53.483959","long":"-2.244644"},{"title":"England","id":"pin-22","lat":"51.5000","long":"0.1167"},{"title":"Staffordshire","id":"pin-24","lat":"","long":""},{"title":"Devon","id":"pin-25","lat":"","long":""}]

  window.initMap = function initMap (markers) {
    try {
      const map = document.getElementById("map-container");
      const mapSliderWrapper = document.querySelector(".map-swiper-wr");
      const mapHint = document.querySelector(".map-hint");
      let activeTitle = "";

      const map2 = new MetaMap(map, {
        mapPath: site_dir + "public/images/map/gb.geo.json",
        zoomedCountries: "GB",
        zoomEnable: true,
        markers: markers,
        countryFillColor: "#EAF7FB",
        countryStrokeWidth: "0",
        on: {
          markerMouseEnter(pin, data) {
            let mapRect = map.getBoundingClientRect();
            let pinRect = pin.getBoundingClientRect();

            const pins = document.querySelectorAll(".markers .marker");

            mapHint.innerHTML = data.title;
            mapHint.style.top = pinRect.y - mapRect.y + pinRect.height + "px";
            mapHint.style.left = pinRect.x - mapRect.x + 10 + "px";
            mapHint.style.transform = `translate(-${mapHint.clientWidth / 2}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
            let hintRect = mapHint.getBoundingClientRect();

            if (hintRect.x + hintRect.width > innerWidth) {
              let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
              mapHint.style.transform = `translate(-${mapHint.clientWidth / 2 - diff}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
            }

            mapHint.classList.add("active");
            map.classList.add("active");

            pins.forEach((el) => {
              el.classList.remove("active");
            });
            pin.classList.add("active");
          },
          markerMouseLeave(pin, data) {
            const pins = document.querySelectorAll(".markers .marker");

            mapHint.classList.remove("active");
            map.classList.remove("active");

            pins.forEach((el) => {
              el.classList.remove("active");

              if (el.classList.contains("is-slider")) {
                map.classList.add("active");

                let mapRect = map.getBoundingClientRect();
                let pinRect = el.getBoundingClientRect();

                mapHint.innerHTML = activeTitle;
                mapHint.style.top = pinRect.y - mapRect.y + pinRect.height + "px";
                mapHint.style.left = pinRect.x - mapRect.x + 10 + "px";
                mapHint.style.transform = `translate(-${mapHint.clientWidth / 2}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;

                let hintRect = mapHint.getBoundingClientRect();

                if (hintRect.x + hintRect.width > innerWidth) {
                  let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
                  mapHint.style.transform = `translate(-${mapHint.clientWidth / 2 - diff}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
                }

                mapHint.classList.add("active");
              }
            });
          },
          markerClick(pin, data) {
            if (!mapSliderWrapper.classList.contains("active")) {
              mapSliderWrapper.classList.add("active");
            }

            if (!pin.classList.contains("is-slider")) {
              let id = data.id;

              const pins = document.querySelectorAll(".markers .marker");
              map.classList.add("active");
              pins.forEach((el) => {
                el.classList.remove("active", "is-slider");
              });
              pin.classList.add("active", "is-slider");

              load("page/search_map", `location=${id}`);
              activeTitle = data.title;
            } else {
              return;
            }
          },
        },
        markerStyle: {
          img: site_dir + "public/images/pin.svg",
          width: 1,
          height: 2,
        },
      });
    } catch (e) {
      setTimeout(function () {
        initMap(locations);
      }, 500);
    }
  };

  if (document.querySelector('#map')) {
    setTimeout(function () {
      initMap(locations);
    }, 500);
  }
  /*map handler end*/

  function smoothScroll(allButtons) {
    allButtons.forEach((elem) => {
      elem.addEventListener("click", function (evt) {
        evt.preventDefault();
        const href = this.getAttribute("href");
        if (href) {
          const anchor = href.split("#")[1] ? href.split("#")[1] : "";
          const hrefWithoutAnchor = href.split("#")[0] ? href.split("#")[0] : "";

          const currentHrefRAW = window.location.href.split("#")[0];
          const currentHref = currentHrefRAW.endsWith("/")
              ? currentHrefRAW.slice(0, -1)
              : currentHrefRAW;

          if (hrefWithoutAnchor === currentHref) {
            // if same, scroll to anchor
            scrollIntoView(document.querySelector(`#${anchor}`), {
              time: 1000,
            });
          } else {
            // if not same, redirect to href
            window.location.href = href;
          }
        }
      });
    });
  }

  if (document.querySelector('.btn')) {
    const allButtons = document.querySelectorAll(".btn");
    smoothScroll(allButtons);
  }

  if (document.querySelector('.first__btn')) {
    const allButtons = document.querySelectorAll(".first__btn");
    smoothScroll(allButtons);
  }

  if (document.querySelector(".choices")) {
    const choicesWrappers = document.querySelectorAll(".choices");
    choicesWrappers.forEach(choiceWrap => {
      choiceWrap.addEventListener("click", () => {
        if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
          const selectSimplebar = choiceWrap.querySelector(
              ".choices__list--dropdown .choices__list"
          );
          const div = document.createElement("div");
          div.classList.add("custom-simplebar-wrapper");
          const content = selectSimplebar.innerHTML;
          selectSimplebar.innerHTML = "";
          div.setAttribute("data-simplebar", "");
          div.innerHTML = content;
          selectSimplebar.appendChild(div);
        }
      });
    });
  }
});

function scrollToCenter(selector) {
  const el = $(selector);

  $("html, body").animate(
      {
        scrollTop: el.offset().top - window.innerHeight / 2 + el.height() / 2,
      },
      400
  );
}

window.addEventListener("DOMContentLoaded", () => {
  if (location.hash) {
    const scrollSection = document.querySelector(location.hash);
    if (scrollSection) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);

      setTimeout(() => {
        scrollToCenter(location.hash);
      }, 500);
    }
  }
});
